<template>
  <v-app style="background-color: transparent !important;">
    <TermsAndConditionsWithActions></TermsAndConditionsWithActions>
    <v-navigation-drawer app permanent mini-variant-width="75" width="230" :mini-variant.sync="mini" dark color="black">
      <div class="d-flex flex-column font-weight-bold" style="height: 100%; font-family: KoHo">
        <div>
          <v-list nav>
            <v-list-item class="px-2" style="height: 50px">
              <v-img v-if="!mini" :src="require(`../../assets/img/${logo}.png`)" max-height="45" width="180" :contain="true" class="mt-2 pt-10 mb-5"></v-img>

              <v-btn x-small v-else color="primary" style="height: 38px; width: 38px"><v-icon small>mdi-menu</v-icon> </v-btn>
              <v-list-item-avatar> </v-list-item-avatar>
            </v-list-item>
            <v-divider></v-divider>
            <template v-for="item in menuItems">
              <v-list-item :disabled="item.disabled" link :to="item.to" :key="item.text">
                <v-list-item-icon class="ml-1 mx-2">
                  <icon large :id="item.icon"></icon> 

                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase">{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </div>
        <v-spacer class="mr-16"></v-spacer>
        <v-btn   @click.stop="mini = !mini" color="black" :rounded="false">
          <v-icon v-if="mini">mdi-chevron-right</v-icon>
          <v-icon v-else>mdi-chevron-left</v-icon>
        </v-btn>
        <div>
          <v-divider></v-divider>
          <v-list shaped nav>
            <v-list-item link :to="{name:'ManageAccount'}">
              <v-list-item-icon>
                <v-icon size="33">mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>ACCOUNT</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon size="33">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>LOGOUT</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main  style="position: relative !important; min-height: 100vh;" >
      <div class="gradientBg"></div>
      <v-slide-x-transition mode="out-in">
        <router-view ></router-view>
      </v-slide-x-transition>
      <div class="grey--text text--lighten-1" style="position: absolute; bottom: 5px; right: 10px; font-size: 7pt;">
        UI: {{ versionInfo.version }}  {{ versionInfo.build }} &nbsp;&nbsp;APP: 0.0.0 
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { getPanelSettings } from "@/api/settings.js";
import { getUIVersionInfo  } from "@/api/version.js";
import TermsAndConditionsWithActions from "@/components/TermsAndConditionsWithActions.vue";

export default {
  name: "Home",
  components: {TermsAndConditionsWithActions},
  data: () => ({
    mini: true,
    logo: "lucid",
    versionData:{
      version: '0.0.0',
      build:'1970-01-01T00:00:00z'
    },
    showLisenceAgreement: false,
    menuItems: [
      // {
      //   id: "HOME",
      //   name: "HOME",
      //   icon: "mdi-home",
      //   to: "/panel",
      //   disabled: false,
      // },
      {
        id: "SAMPLES",
        name: "SAMPLES",
        icon: "dna",
        to: "/user/projects",
        disabled: false,
      },
      {
        id: "COLLAB",
        name: "COLLAB",
        icon: "collab",
        to: "/user/collaborativeanalysis/sharedwithme",
        disabled: false,
      },
      {
        id: "HELP",
        name: "Help Center",
        icon: "help",
        to: "/user/help",
        disabled: false,
      },
    ],
  }),
  methods: {
    logout() {
      this.$root.logout()
    },
  },
  computed:{
    versionInfo(){
      let x = this.versionData
      x['build'] = (new Date(this.versionData.build)).toLocaleString()
      return x
    }
  },
  mounted() {
    const _this = this;
    getPanelSettings("ui_logo", (res) => {
      _this.logo = res.value;
    });
    getUIVersionInfo((res)=>{ console.log(res); _this.versionData = res})
  },

  //   mounted: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = 'hidden'
  // },
  // destroyed: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = null
  // }
};
</script>
<style scoped lang="scss">
.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item--active::before {
  /* background-color: black !important; */
  opacity: 0.14;
}
.gradientBg{
  position: absolute;
  top:0;
  right:0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(215deg, rgb(3, 146, 168) -59.56%, transparent 25.32%) , linear-gradient(45deg, rgb(3, 146, 168) -59.56%, transparent 35.32%); */
  background: linear-gradient(215deg, rgb(168, 3, 3) -5.56%, transparent 90.32%) , linear-gradient(45deg, rgb(3, 146, 168) -59.56%, transparent 35.32%);
}
::v-deep .theme--light.v-application{
  background-color: transparent !important;
}

</style>